<script setup>
import { usePage } from '@inertiajs/vue3'
import { computed, ref, watch } from 'vue'

const props = defineProps({
    displayTimeSeconds: {
        type: Number,
        default: 8,
    }
})

const page = usePage()
const showFlash = ref(false)
const flash = computed(function () {
    displayMessageUntil(props.displayTimeSeconds)
    return page.props.flash
});

let timeoutId = null;
const displayMessageUntil = (displayTimeSeconds) => {
    if (timeoutId !== null) {
        clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
        usePage().props.flash.message = null;
        timeoutId = null;
    }, displayTimeSeconds * 1000);
}

const checkFlashMessageTypeAndDisplay = (message) => {
    if (message.success) {
        flashMessage('success', message.success)
    } else if (message.error) {
        flashMessage('error', message.error)
    } else if (message.info) {
        flashMessage('info', message.info)
    } else if (message.warning) {
        flashMessage('warning', message.warning)
    }
}

watch(page.props, function (newVal, oldVal) {
    if (newVal?.flash !== oldVal?.flash) {
        if (newVal?.flash.message) {
            checkFlashMessageTypeAndDisplay(newVal.flash.message)
        }
        showFlash.value = true
    }
}, {
    immediate: true,
    deep: true,
})

/*<!-- Retrieve the message from the query parameter -->*/
const getMessageFromQueryParam = (type) => {
    return new URLSearchParams(window.location.search).get(type);
}

/*<!-- Create a flash message based on type from query parameter -->*/
const createFlashMessage = (type) => {
    return ref({
        message: {
            success: type === 'success' ? getMessageFromQueryParam('success') : null,
            error: type === 'error' ? getMessageFromQueryParam('error') : null,
            info: type === 'info' ? getMessageFromQueryParam('info') : null,
            warning: type === 'warning' ? getMessageFromQueryParam('warning') : null
        }
    });
};

/*<!-- Define flash message references for different types -->*/
const flashMessageSuccess = createFlashMessage('success');
const flashMessageError = createFlashMessage('error');
const flashMessageInfo = createFlashMessage('info');
const flashMessageWarning = createFlashMessage('warning');

const checkAndDisplayFlashMessages = (type, message) => {
    if (flashMessageSuccess.value.message.success?.length > 0) {
        page.props.flash.message = flashMessageSuccess.value.message
    }
    if (flashMessageError.value.message.error?.length > 0) {
        page.props.flash.message =  flashMessageError.value.message
    }
    if (flashMessageInfo.value.message.info?.length > 0) {
        page.props.flash.message =  flashMessageInfo.value.message
    }
    if (flashMessageWarning.value.message.warning?.length > 0) {
        page.props.flash.message =  flashMessageWarning.value.message
    }
}
checkAndDisplayFlashMessages()
</script>

<script>
import { usePage } from "@inertiajs/vue3";
export const reloadWithFlashMessage = (type, message) => {
    const url = new URL(window.location.href)
    url.searchParams.set(type, message)
    window.location.href = url.toString()
}

let timeoutId = null;
export const flashMessage = (type, message) => {
    const flashObject = {};
    flashObject[type] = message;

    if (timeoutId !== null) {
        clearTimeout(timeoutId);
    }

    if (type === 'success') {
        usePage().props.flash.message = flashObject;

        if (usePage().props.flash.message && usePage().props.flash.message.hasOwnProperty('success')) {
            timeoutId = setTimeout(() => {
                usePage().props.flash.message = null;
                timeoutId = null;
            }, 5000);
        } else {
            usePage().props.flash.message = null;
        }
    }
}
</script>

<template>
    <div v-if="flash.message || page.props.errors">
        <v-alert v-if="flash.message?.success" class="mb-5 mt-5" border="start" variant="tonal" type="success" color="text" :text="flash.message?.success"/>
        <v-alert v-if="flash.message?.info" class="mb-5" border="start" variant="tonal" type="info" :text="flash.message?.info" color="text"/>
        <v-alert v-if="flash.message?.error" class="mb-5" border="start" variant="tonal" type="error" color="primary" :text="flash.message?.error"/>
        <v-alert v-else-if="page.props.errors && Object.keys(page.props.errors).length > 0" class="mb-5" border="start" variant="tonal" type="error"
                 :text="Object.values(page.props.errors).map(error => error.replace(/\.$/, '')).join(', ')" color="primary"/>
        <v-alert v-if="flash.message?.warning" class="mb-5" border="start" variant="tonal" type="warning" :text="flash.message?.warning" color="primary"/>
    </div>
</template>
