export function isActiveMenuItem(links, currentURL) {
    // let filteredCurrentURL = null;
    // let splitString = currentURL.split('/')
    // if (splitString.length >= 3) {
    //     filteredCurrentURL = splitString[2]
    // } else {
    //     filteredCurrentURL = splitString[1]
    // }
    return links.includes(currentURL)
}
